<template>
<div>
     <vs-alert color="danger" class="mb-4" title="Your email address is not verified! Verify to be notified when your order is delivered" >
       <div class="py-3">
            <span class="mr-4">Kindly check your email to verify. If in anyway you didn't receive email, recheck your registered email. <a @click.prevent="resentLink()" href="#">Click to resend link!</a></span>
        </div>
    </vs-alert>
</div>
</template>

<script>
export default {
    methods:{
        resentLink(){
            this.$vs.loading({ color: "#444", type: "sound"})
            this.$http.get(`/account/resend-link`)
            .then((response) => {
                this.$vs.loading.close()
                if(response.data.success){
                    this.$vs.dialog({
                        color: 'primary',
                        title: 'A link has been sent to your email!',
                        text: `${response.data.success}`,
                        acceptText: "Okay"
                    })
                }else{
                    this.alertError(response.data.error)
                }
            }).catch((error)=>{console.log(error)  })
        }
    }
}
</script>
